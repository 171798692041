<template>
  <main>
    <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
    <page-header-compact>
        <span class="d-block">Registro de Antecedentes</span>
        <template v-slot:button>
          <router-link  :to="{ name: 'historias.perfil.usuario', params: { 
            idUsuario: usuario.id
            }}"
            class="btn btn-success btn-sm shadow-sm mx-2">
              Regresar al perfil del paciente <i class="fas fa-history fa-fw"></i>
          </router-link>
        </template>
    </page-header-compact>
    <div class="container-xl px-4">
      <div class="card mt-2">
        <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
      </div>
      <div class="card mt-2">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li v-for="(grupo, i) in grupos" :key="grupo.id" class="nav-item">
              <a class="nav-link " :class="{ active: i === 0 }" :id="`nav_${grupo.id}`" data-toggle="tab"
                :href="`#tab_${grupo.id}`" role="tab" aria-controls="nav-home" aria-selected="true">{{ grupo.nombre }}</a>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content" id="nav-tabContent">
            <div v-for="(grupo, i) in grupos" :key="grupo.id" class="tab-pane fade pt-2"
              :class="{ 'show active': i === 0 }" :id="`tab_${grupo.id}`" role="tabpanel" aria-labelledby="nav-home-tab">
              <div class="row">
                <div class="form-row col-lg-4 col-md-6 col-sm-12 mb-3" v-for="antecedente in grupo.antecedentes"
                  :key="antecedente.id">
                  <buttonsCheck 
                    :antecedente="antecedente" 
                    :label="antecedente.descripcion"
                    :type="antecedente.tipo_antecedente.codigo" 
                    v-model="antecedente.antecedente_usuario.valor"
                  >
                  </buttonsCheck>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
      <div class="row mt-2">
        <div class="col-12">
          <button class="btn btn-success" @click="guardar()">
            <i class="fas fa-save fa-fw text-white-50"></i>
            Guardar
          </button>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import cardInformacionUsuario from "../base/informacionUsuario/cardInformacionUsuario";
import usuarioService from "../../../services/usuarioService";
import PageHeaderCompact from "../../../components/layouts/content/page-header-compact";
import modalInformacionUsuario from "../base/informacionUsuario/modalInformacionUsuario";
import grupoAntecedenteService from "../../../services/grupoAntecedenteService";
import Swal from "sweetalert2";
import antecedenteUsuarioService from "../../../services/antecedenteUsuarioService";
import { isEmpty } from "lodash";
import embarazoService from '../../../services/embarazoService';
import buttonsCheck from './buttonsCheckAntecedentes';

export default {
  components: {
    cardInformacionUsuario,
    modalInformacionUsuario,
    PageHeaderCompact,
    buttonsCheck
  },
  data() {
    return {
      usuario: {},
      grupos: [],
      embarazo_actual: {}
    };
  },
  methods: {
    async cargarUsuario(id) {
      const response = await usuarioService.show(id);
      this.usuario = response.data;

      const res_embarazo_actual = await embarazoService.showByLast(id);
      this.embarazo_actual = res_embarazo_actual.data;
    },
    async guardar() {
     
      let antecedentes_usuario = [];
      this.grupos.forEach((x) => {
        x.antecedentes.forEach((y) => {
          if (y.antecedente_usuario.valor !== "") {
            antecedentes_usuario.push({ ...y.antecedente_usuario });
          }
        });
      });
      this.LoaderSpinnerShow();
      await antecedenteUsuarioService.store({
        antecedentes_usuario: antecedentes_usuario,
      });
      this.LoaderSpinnerHide();
      Swal.fire("Datos Guardados con exito", "", "success");
    },
  },
  async created() {

    this.LoaderSpinnerShow();

    await this.cargarUsuario(this.$route.params.id);

    const filter = {
      sexo: this.usuario.sexo,
      fecha_nacimiento: this.usuario.fecha_nacimiento,
      ...(!isEmpty(this.embarazo_actual) && this.embarazo_actual.activo === 'S' ? { gestante: 'S' } : {})
    };

    const response = await grupoAntecedenteService.index({
      params: filter
    });

    const antecedentes_usuario = await antecedenteUsuarioService.index(
      this.usuario.id
    );

    this.grupos = response.data
      .filter((x) => x.antecedentes.length > 0)
      .map((x) => {
        x.antecedentes.forEach((y, i) => {
          const indice = antecedentes_usuario.data.findIndex(
            (a) => a.id_antecedente === y.id
          );
          x.antecedentes[i]["antecedente_usuario"] = {
            id: indice !== -1 ? antecedentes_usuario.data[indice].id : 0,
            id_usuario: this.usuario.id,
            id_antecedente: y.id,
            valor: indice !== -1 ? antecedentes_usuario.data[indice].valor : "",
          };
        });
        return {
          ...x,
        };
      });
    this.LoaderSpinnerHide();
    
  },
  
};
</script>
